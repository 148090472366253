.hero-banner-wrapper.last-fan-standing > .hero-banner-2 {
  font-size: 18px;
}

.project-container.last-fan-standing {
  padding-bottom: 0px;
}

.project-video.last-fan-standing-1,
.project-video.last-fan-standing-3,
.project-video.last-fan-standing-4,
.project-video.last-fan-standing-5 {
  width: 700px;
  height: 300px;
}

.project-image.last-fan-standing-2 {
  width: 550px;
  height: 500px;
}

@media (max-width: 480px) {
  .hero-banner-wrapper.last-fan-standing > .hero-banner-2 {
    font-size: 16px;
  }
  .project-image.last-fan-standing-2 {
    width: 100%;
    height: 100%;
  }

  .project-video.last-fan-standing-1,
  .project-video.last-fan-standing-3,
  .project-video.last-fan-standing-4,
  .project-video.last-fan-standing-5 {
    width: 100%;
  }
}

@media (min-width: 481px) and (max-width: 1080px) {
  .project-container.last-fan-standing3 {
    padding-bottom: 20px;
  }

  .hero-banner-wrapper.last-fan-standing > .hero-banner-2 {
    font-size: 18px;
    inline-size: auto;
    overflow-wrap: normal;
    padding-bottom: 0px;
  }

  .project-video.last-fan-standing-1,
  .project-video.last-fan-standing-3,
  .project-video.last-fan-standing-4,
  .project-video.last-fan-standing-5 {
    width: 100%;
  }
}
