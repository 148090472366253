.hero-banner-wrapper.smart-USA {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hero-banner-wrapper.smart-USA > .hero-banner-2 {
  font-size: 18px;
  inline-size: 1100px;
  overflow-wrap: break-word;
  justify-content: center;
  padding-bottom: 20px;
}

.project-image-container.smart-USA-1,
.project-image-container.smart-USA-2 {
  height: 600px;
  width: 300px;
  max-width: none;
  max-height: none;
}

.project-image.smart-USA-1,
.project-image.smart-USA-2 {
  height: 600px;
  width: 300px;
}

@media (max-width: 480px) {
  .project-image-container.smart-USA-1,
  .project-image-container.smart-USA-2 {
    height: 100%;
    width: 100%;
  }

  .project-image.smart-USA-1,
  .project-image.smart-USA-2 {
    height: 100%;
    width: 100%;
  }

  .hero-banner-wrapper.smart-USA > .hero-banner-2 {
    font-size: 16px;
    inline-size: auto;
    overflow-wrap: normal;
    padding-bottom: 10px;
  }
}

@media (min-width: 481px) and (max-width: 1080px) {
  .project-image-container.smart-USA-1,
  .project-image-container.smart-USA-2 {
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
  }

  .project-image.smart-USA-1,
  .project-image.smart-USA-2 {
    height: 50%;
    width: 50%;
  }

  .hero-banner-wrapper.smart-USA > .hero-banner-2 {
    font-size: 18px;
    inline-size: auto;
    overflow-wrap: normal;
    padding-bottom: 10px;
  }
}
