.hero-banner-container {
  text-align: center;
  font-size: 48px;
  font-weight: 700;
  padding-right: 10px;
  padding-left: 10px;
}

.portfolio-main {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  overflow: hidden;
  padding: 10px;
}

.portfolio-image {
  width: 700px;
  height: 400px;
  object-fit: cover;
}

.portfolio-image-container {
  max-width: 700px;
  max-height: 400px;
  text-decoration: none;
  font-weight: 700;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.8);
  position: relative;
  height: 100%;
  color: white;
  text-align: center;
  bottom: 102%;
  width: 100%;
  opacity: 0;
  transition: 0.3s ease;
}

.text {
  position: relative;
  text-align: center;
  font-size: 40px;
  top: 40%;
}
@media (max-width: 480px) {
  .hero-banner-container {
    text-align: center;
    padding-top: 40px;
    font-size: 28px;
  }

  .portfolio-main {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
  }

  .overlay {
    opacity: 1;
  }

  .portfolio-image {
    width: 100%;
    height: 100%;
  }
}

@media (min-width: 481px) and (max-width: 1080px) {
  .overlay {
    opacity: 1;
  }
}
