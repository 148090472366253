.hero-banner-wrapper.keep-moving {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hero-banner-wrapper.keep-moving > .hero-banner-2 {
  font-size: 18px;
  inline-size: 1100px;
  overflow-wrap: break-word;
  justify-items: center;
  padding-bottom: 20px;
}

.project-main.keep-moving {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, 500px);
  justify-items: center;
  align-content: center;
}

.project-image-container.keep-moving-2 {
  grid-column-start: 2;
  grid-row: 1 / 5;
  height: 2000px;
  width: 300px;
  max-width: none;
  max-height: none;
}

.project-image.keep-moving-2 {
  height: 2000px;
  width: 300px;
}

.project-image-container.keep-moving-3,
.project-image-container.keep-moving-4,
.project-image-container.keep-moving-5 {
  display: flex;
  justify-items: center;
  align-items: center;
  grid-column-start: 1;
}

@media (max-width: 480px) {
  .hero-banner-wrapper.keep-moving > .hero-banner-2 {
    font-size: 16px;
    inline-size: auto;
    overflow-wrap: normal;
    padding-bottom: 10px;
  }

  .project-main.keep-moving {
    display: flex;
    grid-template-columns: none;
    grid-template-rows: none;
    justify-items: center;
    align-content: center;
  }
}

@media (min-width: 481px) and (max-width: 1080px) {
  .hero-banner-wrapper.keep-moving > .hero-banner-2 {
    font-size: 16px;
    inline-size: auto;
    overflow-wrap: normal;
    padding-bottom: 10px;
  }

  .project-main.keep-moving {
    display: flex;
    grid-template-columns: none;
    grid-template-rows: none;
    justify-items: center;
    align-content: center;
  }

  .project-image-container.keep-moving-3,
  .project-image-container.keep-moving-4,
  .project-image-container.keep-moving-5 {
    width: 100%;
    height: 100%;
  }

  .project-image.keep-moving-3,
  .project-image.keep-moving-4,
  .project-image.keep-moving-5 {
    width: 100%;
    height: 100%;
  }
}
