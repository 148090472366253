.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin: 3vw; */
  padding-top: 2vw;
  padding-left: 5vw;
  padding-right: 5vw;
  gap: 20px;
}

.nav-title {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 700;
}

.nav-name-marisa-salvemini {
  color: #222;
  text-decoration: none;
}

.nav-drop-down {
  display: none;
}

.nav-bottom {
  display: flex;
  color: #999;
  font-size: 14px;
  max-width: 100%;
  gap: 20px;
}

.nav-link {
  text-decoration: none;
  color: #999;
  font-weight: 700;
  border-bottom: 2px solid #f8f8f8;
}

.nav-link.active {
  color: #222;
}

.nav-link:hover {
  color: #222;
}

@media (max-width: 480px) {
  .header {
    z-index: 2;
    position: fixed;
    top: 0;
    width: 96%;
    display: flex;
    flex-direction: row-reverse;
    background-color: #f8f8f8;
    align-items: center;
    padding-top: 2vw;
    padding-bottom: 1vw;
    padding-left: 2vw;
    padding-right: 2vw;
    gap: 20px;
  }

  .nav-title {
    z-index: 2;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 24px;
  }

  .nav-bottom {
    display: none;
  }

  .nav-mobile-background {
    z-index: 1;
    background-color: #f8f8f8;
    position: fixed;
    width: 100%;
    height: 100vh;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .nav-mobile-bottom {
    display: flex;
    color: #999;
    font-size: 18px;
    gap: 20px;
    position: fixed;
    bottom: 40vh;
    left: 33vw;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .nav-drop-down {
    z-index: 999;
    display: block;
    visibility: visible;
    height: 24px;
    cursor: pointer;
  }
}
