.project-image.google-flights-1 {
  width: 700px;
  height: 270px;
  object-fit: cover;
}

.project-image.google-flights-2 {
  width: 700px;
  height: 270px;
  object-fit: cover;
}

.project-video.google-flights-3 {
  width: 700px;
  height: 280px;
}

@media (max-width: 480px) {
  .project-image.google-flights-1,
  .project-image.google-flights-2 {
    cursor: pointer;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .project-video.google-flights-3 {
    cursor: pointer;
    width: 100%;
  }
}
