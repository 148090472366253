.SubPageNav {
  display: flex;
  justify-content: center;
  gap: 5vh;
  font-size: 24px;
  font-weight: 700;
  padding: 10px;
  padding-bottom: 30px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.project-name-container {
  color: #999;
  text-decoration: none;
  border-bottom: 2px solid #f8f8f8;
}

.project-name-container.active {
  color: #222;
}

.project-name:hover {
  color: #222;
}

@media (max-width: 480px) {
  .SubPageNav {
    flex-wrap: wrap;
    row-gap: 1vw;
    column-gap: 10px;
    font-size: 16px;
    padding-bottom: 20px;
    position: inherit;
  }
}

@media screen and (min-width: 481px) and (max-width: 900px) {
  .SubPageNav {
    flex-wrap: wrap;
    row-gap: 1vw;
    column-gap: 10px;
    font-size: 16px;
    padding: 10px;
    position: inherit;
  }
}

@media screen and (min-width: 901px) and (max-width: 1200px) {
  .SubPageNav {
    flex-wrap: wrap;
    row-gap: 1vw;
    column-gap: 20px;
    font-size: 20px;
    padding: 10px;
    position: inherit;
  }
}
