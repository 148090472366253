.hero-banner-wrapper {
  text-align: center;
  font-size: 48px;
  font-weight: 700;
  padding-right: 10px;
  padding-left: 10px;
}

.hero-banner-2 {
  font-size: 20px;
  font-weight: 400;
}

.project-container {
  padding: 10px;
  padding-top: 1vh;
  padding-bottom: 80px;
}

.project-container.pelothon {
  padding-bottom: 0px;
}

.project-main {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
  justify-content: center;
}

.project-image {
  width: 700px;
  height: 400px;
  /* object-fit: cover; */
}

.project-image-container,
.project-text-container {
  max-width: 700px;
  max-height: 400px;
  text-decoration: none;
}

.project-instagram-picture-container {
  width: 400px;
  display: flex;
  justify-content: center;
}

.project-instagram-container {
  width: 450px;
  display: flex;
  justify-content: center;
}

@media (max-width: 480px) {
  .hero-banner-wrapper {
    text-align: center;
    font-size: 28px;
    font-weight: 700;
  }

  .project-main {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
  }

  .project-instagram {
    width: 100%;
  }

  .project-instagram-picture {
    width: 100%;
  }

  .project-container {
    padding: 10px;
    padding-top: 3vh;
    padding-bottom: 0px;
  }

  .project-image {
    cursor: pointer;
    width: 100%;
    height: 100%;
    /* object-fit: cover; */
  }
}
