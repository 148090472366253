.hero-banner-wrapper.clinical-studies > .hero-banner-2 {
  font-size: 40px;
  font-weight: 700;
}

.project-container.clinical-studies,
.project-container.clinical-studies2 {
  padding-bottom: 0px;
}

.project-image-container.clinical-studies-1,
.project-image-container.clinical-studies-5 {
  max-width: 400px;
  max-height: 800px;
  max-width: none;
  max-height: none;
}

.project-image-container.clinical-studies-2,
.project-image-container.clinical-studies-3,
.project-image-container.clinical-studies-4,
.project-image-container.clinical-studies-6 {
  max-width: 500px;
  max-height: 700px;
  max-width: none;
  max-height: none;
}

.project-image.clinical-studies-2,
.project-image.clinical-studies-3,
.project-image.clinical-studies-4,
.project-image.clinical-studies-6 {
  width: 500px;
  height: 600px;
  object-fit: fill;
}

.project-image.clinical-studies-1,
.project-image.clinical-studies-5 {
  width: 400px;
  height: 800px;
  object-fit: fill;
}

@media (max-width: 480px) {
  .project-image-container.clinical-studies-1,
  .project-image-container.clinical-studies-2,
  .project-image-container.clinical-studies-3,
  .project-image-container.clinical-studies-4,
  .project-image-container.clinical-studies-5,
  .project-image-container.clinical-studies-6 {
    display: flex;
    align-items: center;
    max-width: 100%;
    max-height: 100%;
  }

  .project-image.clinical-studies-1,
  .project-image.clinical-studies-2,
  .project-image.clinical-studies-3,
  .project-image.clinical-studies-4,
  .project-image.clinical-studies-5,
  .project-image.clinical-studies-6 {
    max-width: 100%;
    height: 70%;
  }
}

@media (min-width: 481px) and (max-width: 1080px) {
  .hero-banner-wrapper.clinical-studies > .hero-banner-2,
  .hero-banner-wrapper.clinical-studies2 > .hero-banner-1,
  .hero-banner-wrapper.clinical-studies3 > .hero-banner-1 {
    font-size: 32px;
  }

  .project-image-container.clinical-studies-1,
  .project-image-container.clinical-studies-2,
  .project-image-container.clinical-studies-3,
  .project-image-container.clinical-studies-4,
  .project-image-container.clinical-studies-5,
  .project-image-container.clinical-studies-6 {
    display: flex;
    align-items: center;
    max-width: 100%;
    max-height: 100%;
  }

  .project-image.clinical-studies-1,
  .project-image.clinical-studies-2,
  .project-image.clinical-studies-3,
  .project-image.clinical-studies-4,
  .project-image.clinical-studies-5,
  .project-image.clinical-studies-6 {
    max-width: 100%;
    height: 70%;
  }
}
