.project-container.mercedes-benz-games {
  padding-bottom: 0px;
}

.project-video-container.mercedes-benz-games-1,
.project-video-container.mercedes-benz-games-2 {
  width: 700px;
  height: 400px;
  max-width: none;
  max-height: none;
}

.project-video.mercedes-benz-games-1,
.project-video.mercedes-benz-games-2 {
  width: 700px;
  height: 400px;
}

.hero-banner-wrapper.mercedes-benz-games,
.hero-banner-wrapper.mercedes-benz-games2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hero-banner-wrapper.mercedes-benz-games > .hero-banner-2,
.hero-banner-wrapper.mercedes-benz-games2 > .hero-banner-2 {
  font-size: 18px;
  inline-size: 1100px;
  overflow-wrap: break-word;
  justify-content: center;
  padding-bottom: 20px;
}

@media (max-width: 480px) {
  .project-video.mercedes-benz-games-1,
  .project-video.mercedes-benz-games-2 {
    width: 100%;
    height: 100%;
  }

  .hero-banner-wrapper.mercedes-benz-games > .hero-banner-2,
  .hero-banner-wrapper.mercedes-benz-games2 > .hero-banner-2 {
    font-size: 16px;
    inline-size: auto;
    overflow-wrap: normal;
    padding-bottom: 10px;
  }
}

@media (min-width: 481px) and (max-width: 1080px) {
  .project-container.last-fan-standing3 {
    padding-bottom: 20px;
  }

  .hero-banner-wrapper.mercedes-benz-games > .hero-banner-2,
  .hero-banner-wrapper.mercedes-benz-games2 > .hero-banner-2 {
    font-size: 18px;
    inline-size: auto;
    overflow-wrap: normal;
    padding-bottom: 10px;
  }

  .project-video.mercedes-benz-games-1,
  .project-video.mercedes-benz-games-2 {
    width: 100%;
    height: 100%;
  }
}
