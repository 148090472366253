.hero-main-banner-wrapper.instructor-launches {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 52px;
  font-weight: 700;
  padding: 10px;
  padding-bottom: 0px;
  padding-top: 1vh;
}

.project-container.instructor-launches {
  padding-bottom: 0px;
}

.project-image-container.instructor-launches-1 {
  width: 400px;
  height: 700px;
  max-width: none;
  max-height: none;
}

.project-image.instructor-launches-1 {
  width: 400px;
  height: 700px;
}

.instructor-launches-link-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 60px;
}

.instructor-launches-link-text {
  font-size: 40px;
  font-weight: 700;
}

.instructor-launches-link-highlighted-text {
  text-decoration: none;
  color: #00bac7;
  border-bottom: 2px solid #f8f8f8;
}

.instructor-launches-link-highlighted-text:hover {
  border-bottom: 2px solid #00bac7;
}

@media (max-width: 480px) {
  .hero-main-banner-wrapper.instructor-launches {
    font-size: 28px;
    font-weight: 700;
    padding: 10px;
    padding-bottom: 0px;
    padding-top: 5vh;
  }

  .hero-banner-wrapper.instructor-launches > .hero-banner-1 {
    font-size: 25px;
  }
  .hero-banner-wrapper.instructor-launches > .hero-banner-2 {
    font-size: 16px;
    inline-size: auto;
    overflow-wrap: normal;
    padding-bottom: 50px;
  }

  .project-image.instructor-launches-1 {
    width: 100%;
    height: 90%;
  }

  .instructor-launches-link-text {
    font-size: 24px;
    font-weight: 700;
  }
}

@media (min-width: 481px) and (max-width: 1080px) {
  .hero-main-banner-wrapper.instructor-launches {
    font-size: 48px;
    font-weight: 700;
    padding: 0px;
    padding-bottom: 0px;
    padding-top: 5vh;
  }

  .hero-banner-wrapper.instructor-launches > .hero-banner-1 {
    font-size: 32px;
  }
  .hero-banner-wrapper.instructor-launches > .hero-banner-2 {
    font-size: 18px;
    inline-size: auto;
    overflow-wrap: normal;
    padding-bottom: 50px;
  }

  .project-image-container.instructor-launches-1,
  .project-image-container.instructor-launches-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .project-image-container.instructor-launches-1 {
    width: 50%;
    height: 75%;
  }

  .project-image.instructor-launches-1,
  .project-image.instructor-launches-2 {
    width: 75%;
    height: 75%;
  }

  .instructor-launches-link-text {
    font-size: 32px;
    font-weight: 700;
  }
}
