.project-text.cody-bellingers-fathers-day-1 {
  font-size: 20px;
}

@media (max-width: 480px) {
  .project-text.cody-bellingers-fathers-day-1 {
    font-size: 16px;
  }

  .project-container.cody-bellingers-fathers-day {
    padding-bottom: 30px;
  }
}

@media (min-width: 481px) and (max-width: 1080px) {
  .project-text.cody-bellingers-fathers-day-1 {
    font-size: 18px;
  }
}
