.project-main.pelothon {
  gap: 50px;
  padding: 10px;
}

.project-main.pelothon2 {
  display: flex;
  gap: 50px;
  padding: 10px;
  height: 100%;
}

.project-text.pelothon-2 {
  font-size: 20px;
}

.project-image-container.pelothon-3,
.project-image-container.pelothon-4,
.project-image-container.pelothon-5 {
  max-width: 500px;
  max-height: 900px;
  max-width: none;
  max-height: none;
}

.project-image.pelothon-3,
.project-image.pelothon-4,
.project-image.pelothon-5 {
  width: 450px;
  height: 900px;
  object-fit: fill;
}

.project-image.pelothon-4 {
  width: 450px;
  height: 700px;
  object-fit: fill;
}

@media (max-width: 480px) {
  .project-main.pelothon2 {
    gap: 10px;
    padding: 10px;
  }

  .project-text.pelothon-2 {
    font-size: 16px;
  }

  .project-image-container.pelothon-3,
  .project-image-container.pelothon-4,
  .project-image-container.pelothon-5 {
    display: flex;
    align-items: center;
    max-width: 100%;
    max-height: 100%;
  }

  .project-image.pelothon-3,
  .project-image.pelothon-4,
  .project-image.pelothon-5 {
    max-width: 100%;
    height: 70%;
  }
}

@media (min-width: 481px) and (max-width: 1080px) {
  .hero-banner-wrapper.pelothon2 > .hero-banner-2 {
    font-size: 30px;
  }

  .project-text.pelothon-2 {
    font-size: 18px;
  }

  .project-image.bike-plus-launch-1 {
    display: flex;
    align-items: center;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  .project-container.bike-plus-launch {
    padding-bottom: 0px;
  }

  .bike-plus-link-text {
    font-size: 32px;
    font-weight: 700;
  }
}
