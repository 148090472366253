.hero-banner-wrapper.partnerships > .hero-banner-2 {
  font-size: 40px;
  font-weight: 700;
}

.project-container.partnerships,
.project-container.partnerships2 {
  padding-bottom: 0px;
}

.project-main.partnerships {
  gap: 50px;
  padding: 10px;
}

.project-image-container.partnerships-1 {
  max-width: 500px;
  max-height: 1200px;
  max-width: none;
  max-height: none;
}

.project-image-container.partnerships-3 {
  max-width: 600px;
  max-height: 500px;
  max-width: none;
  max-height: none;
}

.project-image-container.partnerships-4 {
  max-width: 500px;
  max-height: 600px;
  max-width: none;
  max-height: none;
}

.project-image-container.partnerships-5 {
  max-width: 500px;
  max-height: 1400px;
  max-width: none;
  max-height: none;
}

.project-image-container.partnerships-6 {
  max-width: 600px;
  max-height: 900px;
  max-width: none;
  max-height: none;
}

.project-image.partnerships-6 {
  width: 600px;
  height: 900px;
  object-fit: fill;
}

.project-image.partnerships-1 {
  width: 500px;
  height: 1200px;
  object-fit: fill;
}

.project-image.partnerships-3 {
  width: 600px;
  height: 500px;
  object-fit: fill;
}

.project-image.partnerships-4 {
  width: 500px;
  height: 600px;
  object-fit: fill;
}

.project-image.pelothon-4 {
  width: 450px;
  height: 700px;
  object-fit: fill;
}

.project-image.partnerships-5 {
  width: 500px;
  height: 1400px;
  object-fit: fill;
}

@media (max-width: 480px) {
  .project-main.partnerships {
    gap: 10px;
    padding: 10px;
  }

  .project-image-container.partnerships-1,
  .project-image-container.partnerships-3,
  .project-image-container.partnerships-4,
  .project-image-container.partnerships-5,
  .project-image-container.partnerships-6 {
    display: flex;
    align-items: center;
    max-width: 100%;
    max-height: 100%;
  }

  .project-image.partnerships-1,
  .project-image.partnerships-3,
  .project-image.partnerships-4,
  .project-image.partnerships-5,
  .project-image.partnerships-6 {
    max-width: 100%;
    height: 70%;
  }
}

@media (min-width: 481px) and (max-width: 1080px) {
  .hero-banner-wrapper.partnerships > .hero-banner-2,
  .hero-banner-wrapper.partnerships2 > .hero-banner-1,
  .hero-banner-wrapper.partnerships3 > .hero-banner-1 {
    font-size: 32px;
  }

  .project-image-container.partnerships-1,
  .project-image-container.partnerships-3,
  .project-image-container.partnerships-4,
  .project-image-container.partnerships-5,
  .project-image-container.partnerships-6 {
    display: flex;
    align-items: center;
    max-width: 100%;
    max-height: 100%;
  }

  .project-image.partnerships-1,
  .project-image.partnerships-3,
  .project-image.partnerships-4,
  .project-image.partnerships-5,
  .project-image.partnerships-6 {
    max-width: 100%;
    height: 70%;
  }
}
