.hero-banner-wrapper.new-feature-launches > .hero-banner-2 {
  font-size: 40px;
  font-weight: 700;
}

.hero-banner-wrapper.new-feature-launches2 > .hero-banner-1 {
  font-size: 40px;
  font-weight: 700;
}

.project-container.new-feature-launches {
  padding-bottom: 0px;
}

.project-image-container.new-feature-launches-1,
.project-image-container.new-feature-launches-2 {
  max-width: 600px;
  max-height: 900px;
  max-width: none;
  max-height: none;
}

.project-image.new-feature-launches-1,
.project-image.new-feature-launches-2 {
  width: 500px;
  height: 800px;
  object-fit: cover;
}

.project-image-container.new-feature-launches-3 {
  max-width: 900px;
  max-height: 500px;
}

.project-image.new-feature-launches-3 {
  object-fit: cover;
  width: 900px;
  height: 500px;
}

@media (max-width: 480px) {
  .hero-banner-wrapper.new-feature-launches > .hero-banner-2 {
    font-size: 24px;
  }

  .hero-banner-wrapper.new-feature-launches2 > .hero-banner-1 {
    font-size: 24px;
  }
  .project-main.new-feature-launches {
    gap: 10px;
    padding: 10px;
  }

  .project-image-container.new-feature-launches-1,
  .project-image-container.new-feature-launches-2,
  .project-image.new-feature-launches-3 {
    display: flex;
    align-items: center;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  .project-image.new-feature-launches-1,
  .project-image.new-feature-launches-2 {
    max-width: 100%;
    height: 80%;
    object-fit: contain;
  }

  .project-image.new-feature-launches-3 {
    height: 100%;
  }
}

@media (min-width: 481px) and (max-width: 1080px) {
  .hero-banner-wrapper.new-feature-launches > .hero-banner-2,
  .hero-banner-wrapper.new-feature-launches2 > .hero-banner-1 {
    font-size: 32px;
  }
  .project-image-container.new-feature-launches-1,
  .project-image-container.new-feature-launches-2,
  .project-image-container.new-feature-launches-3 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .project-image.new-feature-launches-1,
  .project-image.new-feature-launches-2 {
    width: 50%;
    height: 50%;
  }

  .project-image.new-feature-launches-3 {
    width: 75%;
    height: 75%;
  }
}
