.hero-banner-wrapper.day-to-day > .hero-banner-2 {
  font-size: 40px;
  font-weight: 700;
}

.hero-banner-wrapper.day-to-day2 > .hero-banner-1,
.hero-banner-wrapper.day-to-day3 > .hero-banner-1,
.hero-banner-wrapper.day-to-day4 > .hero-banner-1,
.hero-banner-wrapper.day-to-day5 > .hero-banner-1 {
  font-size: 40px;
  font-weight: 700;
}

.project-container.day-to-day2,
.project-container.day-to-day3,
.project-container.day-to-day4 {
  padding-bottom: 0px;
}

.project-image-container.day-to-day-1,
.project-image-container.day-to-day-2,
.project-image-container.day-to-day-4,
.project-image-container.day-to-day-5,
.project-image-container.day-to-day-7 {
  max-width: 550px;
  max-height: 900px;
  max-width: none;
  max-height: none;
}

.project-image-container.day-to-day-3,
.project-image-container.day-to-day-6 {
  max-width: 600px;
  max-height: 600px;
  max-width: none;
  max-height: none;
}

.project-image-container.day-to-day-9 {
  max-width: 400px;
  max-height: 900px;
  max-width: none;
  max-height: none;
}

.project-image-container.day-to-day-10 {
  max-width: 400px;
  max-height: 1400px;
  max-width: none;
  max-height: none;
}

.project-image-container.day-to-day-11 {
  max-width: 750px;
  max-height: 600px;
  max-width: none;
  max-height: none;
}

.project-image.day-to-day-11 {
  width: 750px;
  height: 600px;
  object-fit: fill;
}

.project-image.day-to-day-1,
.project-image.day-to-day-2,
.project-image.day-to-day-4,
.project-image.day-to-day-5,
.project-image.day-to-day-7 {
  width: 550px;
  height: 900px;
  object-fit: fill;
}

.project-image.day-to-day-3,
.project-image.day-to-day-6 {
  width: 600px;
  height: 600px;
  object-fit: fill;
}

.project-image.day-to-day-9 {
  width: 400px;
  height: 900px;
  object-fit: fill;
}

.project-image.day-to-day-10 {
  width: 400px;
  height: 1400px;
  object-fit: fill;
}

.day-to-day-link-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 60px;
}

.day-to-day-link-text {
  font-size: 40px;
  font-weight: 700;
}

.day-to-day-link-highlighted-text {
  text-decoration: none;
  color: #00bac7;
  border-bottom: 2px solid #f8f8f8;
}

.day-to-day-link-highlighted-text:hover {
  border-bottom: 2px solid #00bac7;
}

@media (max-width: 480px) {
  .hero-banner-wrapper.day-to-day > .hero-banner-2,
  .hero-banner-wrapper.day-to-day3 > .hero-banner-1,
  .hero-banner-wrapper.day-to-day4 > .hero-banner-1,
  .hero-banner-wrapper.day-to-day5 > .hero-banner-1 {
    font-size: 30px;
  }

  .day-to-day-link-text {
    font-size: 25px;
    font-weight: 700;
  }

  .day-to-day-link-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 60px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }

  .project-main.pelothon2 {
    gap: 10px;
    padding: 10px;
  }

  .project-image-container.day-to-day-1,
  .project-image-container.day-to-day-2,
  .project-image-container.day-to-day-3,
  .project-image-container.day-to-day-4,
  .project-image-container.day-to-day-5,
  .project-image-container.day-to-day-6,
  .project-image-container.day-to-day-7,
  .project-image-container.day-to-day-8,
  .project-image-container.day-to-day-9,
  .project-image-container.day-to-day-10,
  .project-image-container.day-to-day-11 {
    display: flex;
    align-items: center;
    max-width: 100%;
    max-height: 100%;
  }

  .project-image.day-to-day-1,
  .project-image.day-to-day-2,
  .project-image.day-to-day-3,
  .project-image.day-to-day-4,
  .project-image.day-to-day-5,
  .project-image.day-to-day-6,
  .project-image.day-to-day-7,
  .project-image.day-to-day-8,
  .project-image.day-to-day-9,
  .project-image.day-to-day-10,
  .project-image.day-to-day-11 {
    max-width: 100%;
    height: 70%;
  }
}

@media (min-width: 481px) and (max-width: 1080px) {
  .hero-banner-wrapper.pelothon2 > .hero-banner-2 {
    font-size: 30px;
  }
  .day-to-day-link-text {
    font-size: 32px;
    font-weight: 700;
  }
  .project-image-container.day-to-day-1,
  .project-image-container.day-to-day-2,
  .project-image-container.day-to-day-3,
  .project-image-container.day-to-day-4,
  .project-image-container.day-to-day-5,
  .project-image-container.day-to-day-6,
  .project-image-container.day-to-day-7,
  .project-image-container.day-to-day-8,
  .project-image-container.day-to-day-9,
  .project-image-container.day-to-day-10,
  .project-image-container.day-to-day-11 {
    display: flex;
    align-items: center;
    max-width: 100%;
    max-height: 100%;
  }

  .project-image.day-to-day-1,
  .project-image.day-to-day-2,
  .project-image.day-to-day-3,
  .project-image.day-to-day-4,
  .project-image.day-to-day-5,
  .project-image.day-to-day-6,
  .project-image.day-to-day-7,
  .project-image.day-to-day-8,
  .project-image.day-to-day-9,
  .project-image.day-to-day-10,
  .project-image.day-to-day-11 {
    max-width: 100%;
    height: 70%;
  }
}
