.hero-main-banner-wrapper.events-and-social {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 52px;
  font-weight: 700;
  padding: 10px;
  padding-bottom: 0px;
  padding-top: 1vh;
}

.project-container.events-and-social,
.project-container.events-and-social2,
.project-container.events-and-social3 {
  padding-bottom: 0px;
}

.hero-banner-wrapper.events-and-social2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hero-banner-wrapper.events-and-social2 > .hero-banner-2 {
  font-size: 18px;
  inline-size: 1100px;
  overflow-wrap: break-word;
  justify-content: center;
  padding-bottom: 50px;
}

.project-image-container.events-and-social-2 {
  width: 600px;
  height: 600px;
  max-width: none;
  max-height: none;
}

.project-image.events-and-social-2 {
  width: 600px;
  height: 600px;
}

.project-image-container.events-and-social-3 {
  width: 300px;
  height: 500px;
  max-width: none;
  max-height: none;
}

.project-image.events-and-social-3 {
  width: 300px;
  height: 500px;
}

@media (max-width: 480px) {
  .hero-main-banner-wrapper.events-and-social {
    font-size: 28px;
    font-weight: 700;
    padding: 10px;
    padding-bottom: 0px;
    padding-top: 5vh;
  }

  .hero-banner-wrapper.events-and-social2 > .hero-banner-1 {
    font-size: 25px;
  }

  .hero-banner-wrapper.events-and-social2 > .hero-banner-2 {
    font-size: 16px;
    inline-size: auto;
    overflow-wrap: normal;
    padding-bottom: 50px;
  }

  .project-image-container.events-and-social-2,
  .project-image.events-and-social-2 {
    height: 50%;
    width: 100%;
  }
}

@media (min-width: 481px) and (max-width: 1080px) {
  .project-container.events-and-social4 {
    padding-bottom: 30px;
  }

  .hero-main-banner-wrapper.events-and-social {
    font-size: 48px;
    font-weight: 700;
    padding: 0px;
    padding-bottom: 0px;
    padding-top: 5vh;
  }

  .hero-banner-wrapper.events-and-social > .hero-banner-1,
  .hero-banner-wrapper.events-and-social2 > .hero-banner-1,
  .hero-banner-wrapper.events-and-social3 > .hero-banner-1,
  .hero-banner-wrapper.events-and-social4 > .hero-banner-1 {
    font-size: 32px;
  }
  .hero-banner-wrapper.events-and-social2 > .hero-banner-2 {
    font-size: 18px;
    inline-size: auto;
    overflow-wrap: normal;
    padding-bottom: 50px;
  }

  .project-image-container.holiday-1,
  .project-image-container.holiday-2,
  .project-image-container.holiday-3,
  .project-image-container.holiday-4 {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 75%;
    width: 75%;
  }

  .project-image.holiday-1,
  .project-image.holiday-2,
  .project-image.holiday-3,
  .project-image.holiday-4 {
    height: 75%;
    width: 75%;
  }
}
