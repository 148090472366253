.hero-banner-wrapper.bike-plus-launch > .hero-banner-2 {
  font-size: 40px;
  font-weight: 700;
}

.project-container.bike-plus-launch {
  padding-bottom: 50px;
}

.project-image-container.bike-plus-launch-1 {
  max-width: 1100px;
  max-height: 400px;
  max-width: none;
  max-height: none;
}

.project-image.bike-plus-launch-1 {
  width: 1100px;
  height: 400px;
}

.bike-plus-link-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 60px;
}

.bike-plus-link-text {
  font-size: 40px;
  font-weight: 700;
}

.bike-plus-link-highlighted-text {
  text-decoration: none;
  color: #00bac7;
  border-bottom: 2px solid #f8f8f8;
}

.bike-plus-link-highlighted-text:hover {
  border-bottom: 2px solid #00bac7;
}

@media (max-width: 480px) {
  .hero-banner-wrapper.bike-plus-launch > .hero-banner-2 {
    font-size: 24px;
  }

  .project-container.bike-plus-launch {
    padding-bottom: 30px;
  }

  .project-main.new-feature-launches {
    gap: 10px;
    padding: 10px;
  }

  .bike-plus-link-text {
    font-size: 24px;
    font-weight: 700;
  }

  .project-image-container.bike-plus-launch-1 {
    max-width: 1100px;
    height: 200px;
    max-width: none;
    max-height: none;
  }

  .project-image.bike-plus-launch-1 {
    display: flex;
    align-items: center;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
}

@media (min-width: 481px) and (max-width: 1080px) {
  .hero-banner-wrapper.bike-plus-launch > .hero-banner-2 {
    font-size: 32px;
  }
  .project-image.bike-plus-launch-1 {
    display: flex;
    align-items: center;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  .project-container.bike-plus-launch {
    padding-bottom: 0px;
  }

  .bike-plus-link-text {
    font-size: 32px;
    font-weight: 700;
  }
}
