.project-container.mercedes-summer {
  padding-bottom: 0px;
}

.hero-banner-wrapper.mercedes-summer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hero-banner-wrapper.mercedes-summer > .hero-banner-2 {
  font-size: 18px;
  inline-size: 1100px;
  overflow-wrap: break-word;
  justify-content: center;
  padding-bottom: 20px;
}

.project-image-container.mercedes-summer-1 {
  height: 1000px;
  width: 700px;
  max-width: none;
  max-height: none;
}

.project-image.mercedes-summer-1 {
  height: 1000px;
  width: 700px;
}

.project-image-container.mercedes-summer-2 {
  height: 600px;
  width: 700px;
  max-width: none;
  max-height: none;
}

.project-image.mercedes-summer-2 {
  height: 600px;
  width: 600px;
}

.project-image-container.mercedes-summer-3 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 600px;
  width: 500px;
  max-width: none;
  max-height: none;
}

.project-image.mercedes-summer-3 {
  height: 500px;
  width: 300px;
}

@media (max-width: 480px) {
  .project-video.mercedes-benz-games-1,
  .project-video.mercedes-benz-games-2 {
    width: 100%;
    height: 100%;
  }

  .hero-banner-wrapper.mercedes-summer > .hero-banner-2 {
    font-size: 16px;
    inline-size: auto;
    overflow-wrap: normal;
    padding-bottom: 10px;
  }

  .project-image-container.mercedes-summer-1 {
    height: 100%;
    width: 100%;
  }

  .project-image.mercedes-summer-1 {
    height: 100%;
    width: 100%;
  }

  .project-image-container.mercedes-summer-2 {
    height: 100%;
    width: 100%;
  }
  .project-image.mercedes-summer-2 {
    height: 100%;
    width: 100%;
  }

  .project-image-container.mercedes-summer-3 {
    height: 100%;
    width: 100%;
  }

  .project-image.mercedes-summer-3 {
    height: 100%;
    width: 100%;
  }
}

@media (min-width: 481px) and (max-width: 1080px) {
  .project-container.last-fan-standing3 {
    padding-bottom: 20px;
  }

  .hero-banner-wrapper.mercedes-summer > .hero-banner-2 {
    font-size: 18px;
    padding-bottom: 0px;
  }

  .project-video.last-fan-standing-1,
  .project-video.last-fan-standing-3,
  .project-video.last-fan-standing-4,
  .project-video.last-fan-standing-5 {
    width: 100%;
  }

  .project-image-container.mercedes-summer-1 {
    height: 100%;
    width: 100%;
  }

  .project-image.mercedes-summer-1 {
    height: 100%;
    width: 100%;
  }

  .project-image-container.mercedes-summer-2 {
    height: 100%;
    width: 100%;
  }
  .project-image.mercedes-summer-2 {
    height: 100%;
    width: 100%;
  }

  .project-image-container.mercedes-summer-3 {
    height: 66%;
    width: 66%;
  }

  .project-image.mercedes-summer-3 {
    height: 66%;
    width: 66%;
  }
}
