.hero-banner-wrapper.revolve-wellness {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hero-banner-wrapper.revolve-wellness > .hero-banner-2 {
  font-size: 18px;
  inline-size: 1100px;
  overflow-wrap: break-word;
  justify-content: center;
  padding-bottom: 50px;
}

.project-image-container.revolve-2 {
  height: 500px;
  width: 400px;
  max-width: none;
  max-height: none;
}

.project-image.revolve-2 {
  height: 500px;
  width: 400px;
}

@media (max-width: 480px) {
  .project-image-container.revolve-2 {
    display: flex;
    justify-content: center;
    height: 100%;
    height: 100%;
  }

  .project-image.revolve-2 {
    height: 80%;
    width: 80%;
  }

  .hero-banner-wrapper.revolve-wellness > .hero-banner-2 {
    font-size: 16px;
    inline-size: auto;
    overflow-wrap: normal;
    padding-bottom: 10px;
  }
}

@media (min-width: 481px) and (max-width: 1080px) {
  .hero-banner-wrapper.revolve-wellness > .hero-banner-2 {
    font-size: 16px;
    inline-size: auto;
    overflow-wrap: normal;
    padding-bottom: 10px;
  }
}
