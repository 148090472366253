.hero-banner-wrapper.campaigns > .hero-banner-2 {
  font-size: 40px;
  font-weight: 700;
}

.hero-banner-wrapper.campaigns2 > .hero-banner-1,
.hero-banner-wrapper.campaigns3 > .hero-banner-1,
.hero-banner-wrapper.campaigns4 > .hero-banner-1,
.hero-banner-wrapper.campaigns5 > .hero-banner-1 {
  font-size: 40px;
  font-weight: 700;
}

.project-container.campaigns,
.project-container.campaigns2,
.project-container.campaigns3
/* .project-container.campaigns4  */ {
  padding-bottom: 0px;
}

.project-image-container.campaigns-1,
.project-image-container.campaigns-2,
.project-image-container.campaigns-6,
.project-image-container.campaigns-7,
.project-image-container.campaigns-8,
.project-image-container.campaigns-9,
.project-image-container.campaigns-10,
.project-image-container.campaigns-11 {
  max-width: 600px;
  max-height: 900px;
  max-width: none;
  max-height: none;
}

.project-image.campaigns-1 {
  width: 500px;
  height: 700px;
  object-fit: fill;
}

.project-image.campaigns-9 {
  width: 400px;
  height: 600px;
  object-fit: fill;
}

.project-image.campaigns-10 {
  width: 500px;
  height: 500px;
  object-fit: fill;
}

.project-image.campaigns-11 {
  width: 500px;
  height: 550px;
  object-fit: fill;
}

.project-image.campaigns-2 {
  width: 500px;
  height: 800px;
  object-fit: cover;
}

.project-image.campaigns-6,
.project-image.campaigns-7 {
  width: 600px;
  height: 1600px;
  object-fit: cover;
}

.project-image.campaigns-8 {
  width: 600px;
  height: 600px;
  object-fit: fill;
}

.project-image-container.campaigns-4 {
  height: 1400px;
  width: 600px;
  max-width: none;
  max-height: none;
}

.project-image.campaigns-4 {
  height: 1400px;
  width: 600px;
}

.project-image-container.new-feature-launches-3 {
  max-width: 900px;
  max-height: 500px;
}

.project-image.new-feature-launches-3 {
  object-fit: cover;
  width: 900px;
  height: 500px;
}

@media (max-width: 480px) {
  .hero-banner-wrapper.campaigns > .hero-banner-2 {
    font-size: 24px;
  }

  .hero-banner-wrapper.campaigns2 > .hero-banner-1,
  .hero-banner-wrapper.campaigns3 > .hero-banner-1,
  .hero-banner-wrapper.campaigns4 > .hero-banner-1,
  .hero-banner-wrapper.campaigns5 > .hero-banner-1 {
    font-size: 24px;
  }

  .project-main.campaigns {
    gap: 10px;
    padding: 10px;
  }

  .project-image-container.campaigns-1,
  .project-image-container.campaigns-2,
  .project-image-container.campaigns-6,
  .project-image-container.campaigns-7,
  .project-image-container.campaigns-8,
  .project-image-container.campaigns-9,
  .project-image-container.campaigns-10,
  .project-image-container.campaigns-11 {
    display: flex;
    align-items: center;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  .project-image.campaigns-1,
  .project-image.campaigns-2,
  .project-image.campaigns-6,
  .project-image.campaigns-7,
  .project-image.campaigns-8,
  .project-image.campaigns-9,
  .project-image.campaigns-10,
  .project-image.campaigns-11 {
    max-width: 100%;
    height: 80%;
    object-fit: contain;
  }

  .project-image-container.campaigns-4 {
    display: flex;
    align-items: center;
    max-width: 100%;
    height: 80%;
    object-fit: contain;
  }

  .project-image.campaigns-4 {
    max-width: 100%;
    height: 80%;
    object-fit: contain;
  }
}

@media (min-width: 481px) and (max-width: 1080px) {
  .hero-banner-wrapper.campaigns > .hero-banner-2,
  .hero-banner-wrapper.campaigns2 > .hero-banner-1,
  .hero-banner-wrapper.campaigns3 > .hero-banner-1,
  .hero-banner-wrapper.campaigns4 > .hero-banner-1,
  .hero-banner-wrapper.campaigns5 > .hero-banner-1 {
    font-size: 32px;
  }
  .project-image-container.campaigns-1,
  .project-image-container.campaigns-2,
  .project-image-container.campaigns-6,
  .project-image-container.campaigns-7,
  .project-image-container.campaigns-8,
  .project-image-container.campaigns-9,
  .project-image-container.campaigns-10,
  .project-image-container.campaigns-11 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .project-image.campaigns-1,
  .project-image.campaigns-2,
  .project-image.campaigns-6,
  .project-image.campaigns-7,
  .project-image.campaigns-8,
  .project-image.campaigns-10,
  .project-image.campaigns-11 {
    width: 50%;
    height: 50%;
  }

  .project-image.campaigns-9 {
    width: 75%;
    height: 75%;
  }
}
