.project-container.holiday {
  padding-bottom: 0px;
}

.hero-main-banner-wrapper.holiday {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 52px;
  font-weight: 700;
  padding: 10px;
  padding-bottom: 0px;
  padding-top: 1vh;
}

.hero-banner-wrapper.holiday {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hero-banner-wrapper.holiday > .hero-banner-2 {
  font-size: 18px;
  inline-size: 1100px;
  overflow-wrap: break-word;
  justify-content: center;
  padding-bottom: 50px;
}

.project-main.holiday,
.project-main.holiday2 {
  display: flex;
  flex-direction: column;
}

.project-image-container.holiday-1 {
  max-height: 600px;
}

.project-image.holiday-1 {
  height: 600px;
}

.project-image.holiday-2 {
  object-fit: cover;
}

.project-image-container.holiday-4 {
  max-height: 600px;
}

.project-image.holiday-4 {
  height: 500px;
  object-fit: contain;
}

@media (max-width: 480px) {
  .hero-main-banner-wrapper.holiday {
    font-size: 28px;
    font-weight: 700;
    padding: 10px;
    padding-bottom: 0px;
    padding-top: 5vh;
  }

  .hero-banner-wrapper.holiday > .hero-banner-1 {
    font-size: 25px;
  }

  .hero-banner-wrapper.holiday > .hero-banner-2 {
    font-size: 16px;
    inline-size: auto;
    overflow-wrap: normal;
    padding-bottom: 50px;
  }

  .project-image.holiday-1 {
    height: 100%;
    object-fit: cover;
  }

  .project-image.holiday-2 {
    object-fit: cover;
    height: 100%;
  }

  .project-image-container.holiday-4 {
    max-height: 600px;
  }

  .project-image.holiday-4 {
    height: 100%;
    object-fit: contain;
  }
}

@media (min-width: 481px) and (max-width: 1080px) {
  .project-container.holiday2 {
    padding-bottom: 30px;
  }

  .hero-main-banner-wrapper.holiday {
    font-size: 48px;
    font-weight: 700;
    padding: 0px;
    padding-bottom: 0px;
    padding-top: 5vh;
  }

  .hero-banner-wrapper.holiday > .hero-banner-1,
  .hero-banner-wrapper.holiday2 > .hero-banner-1 {
    font-size: 32px;
  }
  .hero-banner-wrapper.holiday > .hero-banner-2 {
    font-size: 18px;
    inline-size: auto;
    overflow-wrap: normal;
    padding-bottom: 50px;
  }

  .project-image-container.holiday-1,
  .project-image-container.holiday-2,
  .project-image-container.holiday-3,
  .project-image-container.holiday-4 {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 75%;
    width: 75%;
  }

  .project-image.holiday-1,
  .project-image.holiday-2,
  .project-image.holiday-3,
  .project-image.holiday-4 {
    height: 75%;
    width: 75%;
  }
}
