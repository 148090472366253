html {
  min-height: 100%;
  margin: 0;
  box-sizing: border-box;
}
body {
  background-color: #f8f8f8;
  min-height: 100%;
  margin: 0;
  position: relative;
  box-sizing: inherit;
}

#root {
  min-height: 100vh;
}
.App {
  font-family: Jost;
  margin: 0;
  min-height: 100vh;
  color: #222;
  display: flex;
  flex-direction: column;
}
