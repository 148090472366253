.Contact {
  flex: 1;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 10px;
}

.portrait {
  height: 450px;
}

.contact-overlay-text {
  position: relative;
  right: 70%;
  font-size: 30px;
  font-weight: 700;
  background-color: white;
  padding: 5px;
}

.contact-text-container {
  display: flex;
  flex-direction: column;
  max-width: 800px;
  gap: 50px;
  font-size: 20px;
}

@media (max-width: 480px) {
  .Contact {
    padding-top: 5vh;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .contact-overlay-text {
    display: none;
    position: relative;
    right: 0%;
    bottom: 150%;
    font-size: 24px;
    font-weight: 700;
    background-color: white;
    padding: 5px;
  }

  .portrait {
    max-height: 400px;
  }

  .contact-text-container {
    display: flex;
    flex-direction: column;
    max-width: 800px;
    gap: 10px;
    font-size: 16px;
  }
}

@media (min-width: 481px) and (max-width: 1000px) {
  .Contact {
    padding-top: 10vh;
    height: 80%;
    align-items: center;
  }

  .contact-overlay-text {
    position: relative;
    right: 0%;
    bottom: 150%;
    font-size: 24px;
    font-weight: 700;
    background-color: white;
    padding: 5px;
  }

  .portrait {
    max-height: 300px;
  }

  .contact-text-container {
    display: flex;
    flex-direction: column;
    max-width: 800px;
    gap: 10px;
    font-size: 16px;
  }
}

@media (max-height: 760px) {
  .Contact {
    height: 100%;
    padding-top: 3vh;
  }

  .portrait {
    max-height: 350px;
  }
}
